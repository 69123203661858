<template>
	<div class="relative main-h-screen flex">
		<div class="w-full">
			<div class="bg-gray-100 bg-white">
				<div class="flex text-sm justify-center">
					<div class="w-fill  bg-white shadow-md p-2">
						<nav class="flex mb-10" aria-label="Breadcrumb">
							<ol class="inline-flex items-center space-x-1 md:space-x-3">
								<li class="inline-flex items-center">
									<router-link
										:to="{ name: 'Home' }"
										class="inline-flex items-center text-sm text-gray-700 hover:text-gray-900 dark:text-gray-400 dark:hover:text-white"
									>
										<svg class="mr-2 w-4 h-4" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
											<path
												d="M10.707 2.293a1 1 0 00-1.414 0l-7 7a1 1 0 001.414 1.414L4 10.414V17a1 1 0 001 1h2a1 1 0 001-1v-2a1 1 0 011-1h2a1 1 0 011 1v2a1 1 0 001 1h2a1 1 0 001-1v-6.586l.293.293a1 1 0 001.414-1.414l-7-7z"
											></path>
										</svg>
										Home
									</router-link>
								</li>

								<li aria-current="page">
									<div class="flex items-center">
										<svg class="w-6 h-6 text-gray-400" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
											<path
												fill-rule="evenodd"
												d="M7.293 14.707a1 1 0 010-1.414L10.586 10 7.293 6.707a1 1 0 011.414-1.414l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414 0z"
												clip-rule="evenodd"
											></path>
										</svg>
										<span class="ml-1 text-sm font-medium text-gray-400 md:ml-2 dark:text-gray-500">{{ title }}</span>
									</div>
								</li>
							</ol>
							<hr />
						</nav>
						<div class="space-y-10">
							<div class="space-y-2">
								<h2 class="text-2xl text-gray-700 font-semibold">{{ title }}</h2>
							</div>
							<hr />
							<div class="space-y-8">
								<div>
									<p>We do <span class="font-semibold">NOT</span> accept Movie requests.</p>
									<p>We do <span class="font-semibold">NOT</span> provide Adult channels</p>
									<p>We do <span class="font-semibold">NOT</span> accept channel requests at the moment.</p>
								</div>
								<p class="font-semibold"><span class="bg-yellow-300">REQUEST THAT DON'T MEET REQUIREMENTS WILL BE IGNORED AND DELETED</span></p>
								<div class="space-y-1">
									<p>
										We only accept <span class="font-semibold">TV show</span> via our
										<span class="font-semibold">{{ $store.state.brandInfo.requestEmail }}</span> email address only.
									</p>
									<p>
										*TV Shows requests must meet our minimum IMDB <span class="font-semibold">rating</span> and
										<span class="font-semibold">vote</span> requirements found below.
									</p>
									<p class="font-semibold">*You MUST use the search function BEFORE requesting anything to see if we already have it in the system.</p>
									<p>*Requests will be answered within 10 days time</p>
								</div>
								<div class="space-y-1">
									<p><span class="font-semibold">TV Shows Requests:</span> (Limited to <span class="font-semibold">3</span> requests per user)</p>
									<p>
										TV Show <span class="font-semibold">MUST</span> hold an IMDb rating <span class="font-semibold">above 7.0</span> and a
										<span class="font-semibold">minimum</span> of <span class="font-semibold">10,000 (10k)</span> votes on
										<a href="https://www.imdb.com" class="hover:text-blue-500">https://www.imdb.com</a>
									</p>
								</div>
								<div>
									<p>Follow these steps to request a TV show:</p>
									<p>1. Visit <a href="https://www.imdb.com">https://www.imdb.com</a></p>
									<p>
										2. Search IMDB for your TV show and ensure that it has a minimum <span class="font-semibold">6.5 rating</span> or higher and additionally
										that it has more than the required minimum <span class="font-semibold">6,000 votes.</span>
									</p>
									<p>If the TV show has a rating of 7.0 but has less than 10,000 (10k) votes it will not be added so please don't send us a request.</p>
									<p>
										3. If your request meets <span class="font-semibold">BOTH</span> of the requirements, email us your request at
										<a :href="$store.state.brandInfo.requestEmail">{{ $store.state.brandInfo.requestEmail }}</a> and include the
										<span class="font-semibold">IMDB link</span> to that particular show, or click here Please comment here
									</p>
									<p>*Requests sent to other emails or that do not meet both of the above requirements will be ignored/deleted.</p>
								</div>
								<div class="space-y-2">
									<p class="font-semibold">Example:</p>
									<img src="./../../../assets/images/mini-icon/ratings.jpg" alt="ratings" />
									<p>(7.3 is the rating & 111,933 is the number of votes)</p>
								</div>
								<div>
									<p>Movie Requests:</p>
									<p>We do <span class="font-semibold">NOT</span> accept movie requests.</p>
									<p>
										Movies are added automatically when they are released in Blu-ray & based on their IMDB rating. Do
										<span class="font-semibold">not</span> send requests for movies.
									</p>
								</div>
								<div>
									<p>NOTE: You may see TV shows and Movies that have lower ratings and votes that already exist in the system.</p>
									<p>These are automatically added by the system however ALL requests must still meet the above requirements for consideration.</p>
								</div>
							</div>
							<hr />
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
export default {
	name: 'CanRequestChannelsMovieShows',
	data() {
		return {
			title: this.$route.meta.title
		};
	}
};
</script>

<style scoped></style>
